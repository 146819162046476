.backgroundImg{
     background-image: url(../img/exploremap.png) ;
    background-color: #355b7a !important;
    background-size: cover;
    background-position: center;
    min-height: 80vh; /* Set a minimum height to cover the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
}
.map-container {
  background-image: url(../img/exploremap.png) !important;
    height: 600px; /* Adjust the height as needed */
    width: 145% !important;
    /* border: 1px solid black; */
    border-radius: 19px;
    
    overflow: hidden; /* Hide overflowing content */
    /* background-color: aquamarine; */
    margin-top: 10px;  /* Adjust the top margin as needed */
  margin-bottom: 10px;
  }
  .map-container:hover{
 font-size: larger;
 font-weight:600;
  }
  
  .district-details-container {
    height: 500px; /* Adjust the height as needed */
    width: 145% mportant !important;
    border: 1px solid black;
    border-radius: 19px;
    overflow: hidden; /* Hide overflowing content */
  }
  .leaflet-container {
    background: #67b4bf !important;
    outline-offset: 1px;
}
.leaflet-container {
    -webkit-tap-highlight-color: #67b4bf ;
}
.leaflet-container {
    /* overflow: hidden; */
} 
  
