.fa-facebook-f{
    color: rgb(220, 216, 245);
    /* padding:20px; */
}

.fa-youtube{
    color: red;
    /* padding:20px */
}

.fa-twitter{
    color: rgb(20, 69, 115);

}