.CustomStyleSlider{
  #app { height: 100%; }
  html,


  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background-image:url(./images/img_4.jpg) !important;
    height: 100vh;
    background: #9395cda4;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  .CustomStyleSlider{
    /* background-image:url(./images/img_4.jpg) !important; */
  }
  h1{
    /* background-color: #7171ab; */
    color: #e58c1b;
    font-size: 55px;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 50px;
    text-align: center;
  }
  
  
  .slide-text {
    font-weight: 800;
    font-size: 20px; /* Adjust the font size as needed */
    color: #e58c1b; /* Adjust the text color */
    margin-top: 10px; /* Adjust the margin-top to create space between the image and text */
  }
  
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 250px;
    /* height: 300px; */
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    -webkit-box-reflect: below 1px linear-gradient(transparent, transparent , #0002 , #0004);
  }
  
  .swiper-slide:hover img {
    transform: scale(1.2);
  }
  
  .swiper_container {
    /* background-color: #7171ab; */
    height: 33rem;
    padding: 2rem 0;
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
  
  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .swiper-slide img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease-in-out;
   /* / filter:blur(5px); */
    border-radius: 2rem;
  }
  .swiper-slide.swiper-slide-active img {
    filter: blur(0);
  }
  
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display:none;
  }
  
  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-slide .slide-text {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    text-align:center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:x-large;
    color:#e58c1b;
    
  }
  
  .swiper-slide:hover .slide-text {
    font-size: 40px;;
  }
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }



  .swiper-slide.swiper-slide-active img {
    filter: blur(0);
  }
  
  
  .swiper-slide:hover img {
    transform: scale(1.2);
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display:none;
  }

  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }

  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(230, 232, 235, 0.1));
  }
  

  .slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }
  
  .slider-controler .slider-arrow::after {
    content: '';
  }
  
  .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }

  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
}