.section-header{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: 1.42857143;
    font-family: Poppins,sans-serif;
    font-size: 14px;
    color: #a09e9c;
    text-transform: initial;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}



.container {
  margin-top: 20px;
}

.section-header {
  text-align: center;
}

.head {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color:#e58c1b;
  
}

.para {
  font-size: 18px;
  color: #666;
}

.blog-content {
  margin-top: 30px;
}

.single-blog-item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.single-blog-item:hover {
  transform: scale(1.05);
}

.single-blog-item-img img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.single-blog-item-txt {
  padding: 20px;
}

.single-blog-item-txt h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.single-blog-item-txt h4 {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.single-blog-item-txt p {
  font-size: 16px;
  color: #333;
}


@media (max-width: 768px) {
  .single-blog-item {
    flex-basis: 100%;
  }
} 




.blog-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}

.blog-modal-content {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-width: 600px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}


.blog-modal-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.blog-modal-content img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.blog-modal-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.blog-modal-content button {
  background: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .blog-modal-content {
    max-width: 60%; /* Adjust as needed */
  }
}


.single-blog-item {
  height: 300px; /* Set a fixed height for the blog item */
}


.single-blog-item-img img {
  width: 100%;
  height: 100%; /* Ensure the image fills the container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}


