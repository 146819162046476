
 :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #7AB730;
    --secondary: #f4faec;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #FFFFFF;
    --dark: #212121;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Poppins", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    /* background-color: #23313a; */
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #656565;
    text-align: left;
    background-color: #7daacf;
  }
  
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  p {
    text-align: justify;
    color:#337f9d !important;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ul{
    justify-content: center;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color:#ffc107;
    text-decoration: none;
    background-color: transparent;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: #223b03;
    text-decoration: underline;
    transform: scaleX(1);
  }
  
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  h1, h2, h3, h4, h5, h6,
   .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    /* color: #36498f; */
    color:#e58c1b;
  }
  .h1{
    color: #36498f;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ;
    }
  }
  
  h2, .h2 {
    font-size: 2rem;
  }
  
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ;
    }
  }
  
  h3, .h3 {
    font-size: 1.75rem;
  }
  
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ;
    }
  }
  
  h4, .h4 {
    font-size: 1.5rem;
  }
  
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ;
    }
  }
  
  h5, .h5 {
    font-size: 1.25rem;
  }
  
  h6, .h6 {
    font-size: 1rem;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ;
    }
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ;
    }
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ;
    }
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ;
    }
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #F3F3F3;
    border: 1px solid #dee2e6;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1347px;
    }
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #656565;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #656565;
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #daebc5;
  }
  
  .table-primary th,
  .table-primary td,
  .table-primary thead th,
  .table-primary tbody + tbody {
    border-color: #bada93;
  }
  
  .table-hover .table-primary:hover {
    background-color: #cee4b2;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #cee4b2;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #fcfefa;
  }
  
  .table-secondary th,
  .table-secondary td,
  .table-secondary thead th,
  .table-secondary tbody + tbody {
    border-color: #f9fcf5;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #effae5;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #effae5;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb;
  }
  
  .table-success th,
  .table-success td,
  .table-success thead th,
  .table-success tbody + tbody {
    border-color: #8fd19e;
  }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb;
  }
  
  .table-info th,
  .table-info td,
  .table-info thead th,
  .table-info tbody + tbody {
    border-color: #86cfda;
  }
  
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba;
  }
  
  .table-warning th,
  .table-warning td,
  .table-warning thead th,
  .table-warning tbody + tbody {
    border-color: #ffdf7e;
  }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb;
  }
  
  .table-danger th,
  .table-danger td,
  .table-danger thead th,
  .table-danger tbody + tbody {
    border-color: #ed969e;
  }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: white;
  }
  
  .table-light th,
  .table-light td,
  .table-light thead th,
  .table-light tbody + tbody {
    border-color: white;
  }
  
  .table-hover .table-light:hover {
    background-color: #f2f2f2;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c1c1c1;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #8c8c8c;
  }
  
  .table-hover .table-dark:hover {
    background-color: #b4b4b4;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b4b4;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }
  
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55;
  }
  
  .table-dark.table-bordered {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .table-responsive > .table-bordered {
    border: 0;
  }
   @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  
  .btn:hover {
    color: #656565;
    text-decoration: none;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.25);
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #669928;
    border-color: #5f8f25;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #669928;
    border-color: #5f8f25;
    box-shadow: 0 0 0 0.2rem rgba(142, 194, 79, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5f8f25;
    border-color: #588423;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(142, 194, 79, 0.5);
  }
  
  .btn-secondary {
    color: #212529;
    background-color: #f4faec;
    border-color: #f4faec;
  }
  
  .btn-secondary:hover {
    color: #212529;
    background-color: #e2f2ce;
    border-color: #dcefc4;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #e2f2ce;
    border-color: #dcefc4;
    box-shadow: 0 0 0 0.2rem rgba(212, 218, 207, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #f4faec;
    border-color: #f4faec;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #dcefc4;
    border-color: #d6edb9;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 218, 207, 0.5);
  }
  
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }
  
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }
  
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
  
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }
  
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }
  
  .btn-light {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }
  
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
  }
  
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }
  
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
  
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
  }
  
  .btn-dark {
    color: #fff;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #080808;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #080808;
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #080808;
    border-color: #010101;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5);
  }
  
  .btn-outline-primary {
    color: #ffc107;
    border-color: #7AB730;
  }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #7AB730;
    background-color: transparent;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.5);
  }
  
  .btn-outline-secondary {
    color: #f4faec;
    border-color: #f4faec;
  }
  
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #f4faec;
    border-color: #f4faec;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 250, 236, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #f4faec;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #f4faec;
    border-color: #f4faec;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 250, 236, 0.5);
  }
  
  .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-light {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
  
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FFFFFF;
    background-color: transparent;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .btn-outline-dark {
    color: #212121;
    border-color: #212121;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212121;
    background-color: transparent;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  }
  
  .btn-link {
    font-weight: 400;
    color: #7AB730;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #527a20;
    text-decoration: underline;
  }
  
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  
  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%;
  }
  
  .fade {
    transition: opacity 0.15s linear;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  
  .fade:not(.show) {
    opacity: 0;
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative;
  }
  
  .dropdown-toggle {
    white-space: nowrap;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #656565;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  
  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #7AB730;
  }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
  }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
  }
  
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1;
  }
  
  .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .btn-toolbar .input-group {
    width: auto;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
  }
  
  .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
  }
  
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
  }
  
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control-plaintext + .form-control,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: -1px;
  }
  
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  
  .input-group-prepend,
  .input-group-append {
    display: flex;
  }
  
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  
  .input-group-prepend .btn:focus,
  .input-group-append .btn:focus {
    z-index: 3;
  }
  
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px;
  }
  
  .input-group-prepend {
    margin-right: -1px;
  }
  
  .input-group-append {
    margin-left: -1px;
  }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
  }
  
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  
  .input-group-lg > .form-control:not(textarea),
  .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px);
  }
  
  .input-group-lg > .form-control,
  .input-group-lg > .custom-select,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .input-group-sm > .form-control:not(textarea),
  .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
  }
  
  .input-group-sm > .form-control,
  .input-group-sm > .custom-select,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 1.75rem;
  }
  
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    print-color-adjust: exact;
  }
  
  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
  
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #7AB730;
    background-color: #7AB730;
  }
  
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.25);
  }
  
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #b8e087;
  }
  
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d0eaaf;
    border-color: #d0eaaf;
  }
  
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
  }
  
  .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
  }
  
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #7AB730;
    background-color: #7AB730;
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  }
  
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(122, 183, 48, 0.5);
  }
  
  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(122, 183, 48, 0.5);
  }
  
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  
  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(122, 183, 48, 0.5);
  }
  
  .custom-switch {
    padding-left: 2.25rem;
  }
  
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }
  
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }
  
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
  }
  
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(122, 183, 48, 0.5);
  }
  
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0;
    appearance: none;
  }
  
  .custom-select:focus {
    border-color: #b8e087;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.25);
  }
  
  .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }
  
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
  
  .custom-select::-ms-expand {
    display: none;
  }
  
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
  }
  
  .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      transition: none;
    }
  }
  
  .custom-range::-webkit-slider-thumb:active {
    background-color: #d0eaaf;
  }
  
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
  }
  
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #7AB730;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      transition: none;
    }
  }
  
  .custom-range::-moz-range-thumb:active {
    background-color: #d0eaaf;
  }
  
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
  }
  
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #7AB730;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      transition: none;
    }
  }
  
  .custom-range::-ms-thumb:active {
    background-color: #d0eaaf;
  }
  
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }
  
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
  }
  
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
  }
  
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  
  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
  }
  
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
    }
  }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #F3F3F3;
    border-color: #dee2e6 #dee2e6 #F3F3F3;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #7AB730;
  }
  
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
  }
  
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap;
  }
  
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  
  .card > .list-group:first-child {
    border-top-width: 0;
  }
  
  .card > .list-group:last-child {
    border-bottom-width: 0;
  }
  
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img,
  .card-img-top,
  .card-img-bottom {
    flex-shrink: 0;
    width: 100%;
  }
  
  .card-deck .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  
  .card-group > .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
  }
  
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  
  .accordion {
    overflow-anchor: none;
  }
  
  .accordion > .card {
    overflow: hidden;
  }
  
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
  }
  
  .accordion > .card > .card-header {
    margin-bottom: -1px;
  }
  
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
  }
  
  .breadcrumb-item {
    display: flex;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #7AB730;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  
  .page-link:hover {
    z-index: 2;
    color: #527a20;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.25);
  }
  
  .page-item:first-child .page-link {
    margin-left: 0;
  }
  
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  
  a.badge:hover, a.badge:focus {
    text-decoration: none;
  }
  
  .badge:empty {
    display: none;
  }
  
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
  }
  
  .badge-primary {
    color: #fff;
    background-color: #7AB730;
  }
  
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #5f8f25;
  }
  
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 183, 48, 0.5);
  }
  
  .badge-secondary {
    color: #212529;
    background-color: #f4faec;
  }
  
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #dcefc4;
  }
  
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 250, 236, 0.5);
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34;
  }
  
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b;
  }
  
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
  }
  
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
  }
  
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .badge-light {
    color: #212529;
    background-color: #FFFFFF;
  }
  
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e6e6e6;
  }
  
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .badge-dark {
    color: #fff;
    background-color: #212121;
  }
  
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #080808;
  }
  
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  }
  
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
  }
  
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 4rem;
  }
  
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  
  .alert-primary {
    color: #3f5f19;
    background-color: #e4f1d6;
    border-color: #daebc5;
  }
  
  .alert-primary hr {
    border-top-color: #cee4b2;
  }
  
  .alert-primary .alert-link {
    color: #24370e;
  }
  
  .alert-secondary {
    color: #7f827b;
    background-color: #fdfefb;
    border-color: #fcfefa;
  }
  
  .alert-secondary hr {
    border-top-color: #effae5;
  }
  
  .alert-secondary .alert-link {
    color: #656862;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-success hr {
    border-top-color: #b1dfbb;
  }
  
  .alert-success .alert-link {
    color: #0b2e13;
  }
  
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  
  .alert-info hr {
    border-top-color: #abdde5;
  }
  
  .alert-info .alert-link {
    color: #062c33;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-warning hr {
    border-top-color: #ffe8a1;
  }
  
  .alert-warning .alert-link {
    color: #533f03;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  
  .alert-danger .alert-link {
    color: #491217;
  }
  
  .alert-light {
    color: #858585;
    background-color: white;
    border-color: white;
  }
  
  .alert-light hr {
    border-top-color: #f2f2f2;
  }
  
  .alert-light .alert-link {
    color: #6c6c6c;
  }
  
  .alert-dark {
    color: #111111;
    background-color: lightgray;
    border-color: #c1c1c1;
  }
  
  .alert-dark hr {
    border-top-color: #b4b4b4;
  }
  
  .alert-dark .alert-link {
    color: black;
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7AB730;
    transition: width 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none;
    }
  }
  
  .media {
    display: flex;
    align-items: flex-start;
  }
  
  .media-body {
    flex: 1;
  }
  
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .list-group-item-action:active {
    color: #656565;
    background-color: #e9ecef;
  }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
  }
  
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #7AB730;
    border-color: #7AB730;
  }
  
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }
  
  .list-group-horizontal {
    flex-direction: row;
  }
  
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
  
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  
  .list-group-item-primary {
    color: #3f5f19;
    background-color: #daebc5;
  }
  
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3f5f19;
    background-color: #cee4b2;
  }
  
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3f5f19;
    border-color: #3f5f19;
  }
  
  .list-group-item-secondary {
    color: #7f827b;
    background-color: #fcfefa;
  }
  
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7f827b;
    background-color: #effae5;
  }
  
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7f827b;
    border-color: #7f827b;
  }
  
  .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
  }
  
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
  }
  
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
  }
  
  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }
  
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
  }
  
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
  }
  
  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }
  
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
  }
  
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
  }
  
  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }
  
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
  }
  
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
  }
  
  .list-group-item-light {
    color: #858585;
    background-color: white;
  }
  
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
  }
  
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585;
  }
  
  .list-group-item-dark {
    color: #111111;
    background-color: #c1c1c1;
  }
  
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #111111;
    background-color: #b4b4b4;
  }
  
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #111111;
    border-color: #111111;
  }
  
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ;
    }
  }
  
  .close:hover {
    color: #000;
    text-decoration: none;
  }
  
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  
  a.close.disabled {
    pointer-events: none;
  }
  
  .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
  }
  
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  
  .toast.showing {
    opacity: 1;
  }
  
  .toast.show {
    display: block;
    opacity: 1;
  }
  
  .toast.hide {
    display: none;
  }
  
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .toast-body {
    padding: 0.75rem;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  
  .modal.show .modal-dialog {
    transform: none;
  }
  
  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }
  
  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
  }
  
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
  }
  
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: "";
  }
  
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  
  .modal-backdrop.fade {
    opacity: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
  }
  
  .modal-footer > * {
    margin: 0.25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content;
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  
  .tooltip.show {
    opacity: 0.9;
  }
  
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  
  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  
  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
  }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
  }
  
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px);
  }
  
  .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  
  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);
  }
  
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }
  
  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
  }
  
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  
  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #212121;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  
  .popover-header:empty {
    display: none;
  }
  
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #656565;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      transition: none;
    }
  }
  
  .carousel-indicators .active {
    opacity: 1;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
  }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
  }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  
  .align-baseline {
    vertical-align: baseline !important;
  }
  
  .align-top {
    vertical-align: top !important;
  }
  
  .align-middle {
    vertical-align: middle !important;
  }
  
  .align-bottom {
    vertical-align: bottom !important;
  }
  
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  
  .align-text-top {
    vertical-align: text-top !important;
  }
  
  .bg-primary {
    background-color: #7AB730 !important;
  }
  
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #5f8f25 !important;
  }
  
  .bg-secondary {
    background-color: #f4faec !important;
  }
  
  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #dcefc4 !important;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1e7e34 !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #117a8b !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #d39e00 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #bd2130 !important;
  }
  
  .bg-light {
    /* background-color:#e8f3ef !important; */
    background-color:#060606 !important;
  }
  
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #e6e6e6 !important;
  }
  
  .bg-dark {
    background-color:#060606 !important;
  }
  
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #080808 !important;
  }
  
  .bg-white {
    background-color: #fff !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  
  .border {
    border: 1px solid #dee2e6 !important;
  }
  
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-right-0 {
    border-right: 0 !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-left-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: #7AB730 !important;
  }
  
  .border-secondary {
    border-color: #f4faec !important;
  }
  
  .border-success {
    border-color: #28a745 !important;
  }
  
  .border-info {
    border-color: #17a2b8 !important;
  }
  
  .border-warning {
    border-color: #ffc107 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  .border-light {
    border-color: #FFFFFF !important;
  }
  
  .border-dark {
    border-color: #212121 !important;
  }
  
  .border-white {
    border-color: #fff !important;
  }
  
  .rounded-sm {
    border-radius: 0.2rem !important;
  }
  
  .rounded {
    border-radius: 0.25rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-lg {
    border-radius: 0.3rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-pill {
    border-radius: 50rem !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-inline-flex {
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  
  
  @supports (position: sticky) {
    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
    box-shadow: none !important;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-auto {
    height: auto !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .min-vw-100 {
    min-width: 100vw !important;
  }
  
  .min-vh-100 {
    min-height: 100vh !important;
  }
  
  .vw-100 {
    width: 100vw !important;
  }
  
  .vh-100 {
    height: 100vh !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .mb-0:hover{
    transition: all 0.3s ease;
    transform: scale(1.2); 
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .m-n1 {
    margin: -0.25rem !important;
  }
  
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }
  
  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }
  
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  
  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }
  
  .m-n2 {
    margin: -0.5rem !important;
  }
  
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }
  
  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }
  
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  
  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }
  
  .m-n3 {
    margin: -1rem !important;
  }
  
  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important;
  }
  
  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important;
  }
  
  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important;
  }
  
  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important;
  }
  
  .m-n4 {
    margin: -1.5rem !important;
  }
  
  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important;
  }
  
  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important;
  }
  
  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important;
  }
  
  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important;
  }
  
  .m-n5 {
    margin: -3rem !important;
  }
  
  .mt-n5,
  .my-n5 {
    margin-top: -3rem !important;
  }
  
  .mr-n5,
  .mx-n5 {
    margin-right: -3rem !important;
  }
  
  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem !important;
  }
  
  .ml-n5,
  .mx-n5 {
    margin-left: -3rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
      margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -3rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem !important;
    }
    .m-md-n5 {
      margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -3rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
      margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -3rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
      margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -3rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  
  /* .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  } */
  
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-weight-bolder {
    font-weight: bolder !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    /* color:  #014ebf !important; */
    color:white !important;
  }
  
  .text-primary {
    color: #079d14!important;
  }
  
  a.text-primary:hover, a.text-primary:focus {
    color: #527a20 !important;
  }
  
  .text-light {
    color: #FFFFFF !important;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #d9d9d9 !important;
  }
  
  .text-dark {
    color: #316c54!important;
  }
  
  a.text-dark:hover, a.text-dark:focus {
    color: black !important;
  }
  
  .text-body {
    color: #656565 !important;
  }
  
  .text-black-50 {
    color: #8bfd44 !important;
  }
  
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .text-decoration-none {
    text-decoration: none !important;
  }
  
  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
  }
  
  .text-reset {
    color: inherit !important;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #000;
    }
   
  }
  
  /********** Custom CSS ************/
  h1,
  h2,
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  h3,
  h4,
  .font-weight-semi-bold {
    font-weight: 600 !important;
  }
  
  h5,
  h6,
  .font-weight-medium {
    font-weight: 1000 !important;
  }
  
  .btn-square {
    width: 36px;
    height: 36px;
  }
  
  .btn-sm-square {
    width: 28px;
    height: 28px;
  }
  
  .btn-lg-square {
    width: 46px;
    height: 46px;
  }
  
  .btn-square,
  .btn-sm-square,
  .btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  
  .back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 11;
  }
  
  .nav-bar::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background: #080808;
  }
  
  .navbar-light .navbar-nav .nav-link {
    padding: 30px 95px;
    color:#0f0cf9;
    font-weight: 700;
    outline: none;
  }
  
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link.active {
    color:#0c5460;
  }
  
  @media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link {
      padding: 10px 15px;
    }
  }
  
  @media (min-width: 992px) {
    #header-carousel,
    .page-header {
      margin-top: -42px;
    }
  }
  
  .carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  @media (max-width: 576px) {
    .carousel-caption h4 {
      font-size: 18px;
    }
    .carousel-caption h1 {
      font-size: 30px;
    }
  }
  
  @media (min-width: 768px) {
    .booking {
      position: relative;
      margin-top: -60px !important;
      z-index: 1;
    }
  }
  
  @media (min-width: 992px) {
    .about-text {
      margin-left: -120px;
    }
  }
  
  .page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/carousel-2.jpg), no-repeat center center;
    background-size: cover;
  }
  
  .destination-item img {
    transition: .5s;
  }
  
  .destination-item:hover img {
    transform: scale(1.3);
  }
  
  .destination-overlay {
    /* position: absolute; */
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    transition: .5s;
    z-index: 1;
  }
  
  .destination-item:hover .destination-overlay {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-width: 30px;
  }
  
  .bg-registration {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/carousel-1.jpg), no-repeat center center;
    background-size: cover;
  }
  
  .z-index{
    z-index: 9;
  }
  .max-width-900{
    max-width: 900px;
  }
  .width-height-45{
    width: 45px; height: 45px;

  }
  .padding-30{
    padding: 30px;

  }

  .mh60{
    min-height: 60px;
  }

  .ht47{
     height: 47px;
  }

  .height47-margin-2{
    height: 47px; margin-top: -2px;
  }
  .border-color-rgba{
  border-color: rgba(256, 256, 256, .1) !important;
  }
  .letter-spacing-5px{
    letter-spacing: 5px;
  }
  .margin-top-90px{
    margin-top: 4px;
  }
  .navbar-nav a.nav-link {
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.navbar-nav a.nav-link:hover {
    transform: scale(1.1);
}
.navbar {
  background-color: #d8ebe7!important;
  
}

.letter-spacing-5px{
  letter-spacing: 5px;
}

.backgroundImg{
  background-image: url(../img/destination-1.jpg);
}
