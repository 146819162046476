body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.colorHeder{
  color: #3f659d;
  padding-bottom: 20px;
}
.backgrounCustom{
  background: #f28130;
}
.customBackgroundforDes{
  background-color: #1085748a;
}

.backgroundImgForDestination{
  /* background-image: url(/src/img/bgdest1.jpg); */
  /* background-image: url(/src/img/dal-lake.cms); */
  background-color: #355b7a;
}

.border{
  border: 1px solid black;
  border-radius: 19px;
}

.customeMenuList{
  background: #1085748a;
  font-family: 'Playfair Display';
  font-size: 26px;
  overflow-y: auto;


  a {
    color:#26298b;
   
  }

  li {
    padding: 0.6vh 0;
    list-style: none;
  }
}

.customBackground{
  background: #f28130;
}

.borderTopCustom{
  border-top: #f28130 38px solid;
  
  ul{
    margin-left: -41px;

  }
}

.paddingleft{
  padding-left:70px;
}


